<template>
  <div>
    <!-- <b-card class="p-0 filter-card rounded-lg">
      <b-row>
        <b-col cols="12">
          <h3 class="ml-1 mb-2">Filters</h3>
        </b-col>
        <b-col cols="4">
          <v-select
            v-model="filter.school_id"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :options="schools"
            class="w-100 mb-0"
            :reduce="(val) => val.id"
            placeholder="School"
            :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
          >
            <template v-slot:option="option">
              {{ $i18n.locale == "ar" ? option.name_local : option.name }}
            </template>
            <template #selected-option="{ name, name_local }">
              <div style="display: flex; align-items: baseline">
                <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                <strong v-else>{{ name }} </strong>
              </div>
            </template>
            <template #no-options>
              {{ $t("noMatching") }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="4">
          <v-select
            v-model="filter.type_id"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :options="types"
            class="w-100 mb-0"
            :reduce="(val) => val.id"
            placeholder="User Type"
            :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
          >
            <template v-slot:option="option">
              {{ $i18n.locale == "ar" ? option.name_local : option.name }}
            </template>
            <template #selected-option="{ name, name_local }">
              <div style="display: flex; align-items: baseline">
                <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                <strong v-else>{{ name }} </strong>
              </div>
            </template>
            <template #no-options>
              {{ $t("noMatching") }}
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card> -->
    <b-card class="w-100 d-flex rounded-lg">
      <b-row>
        <b-col md="12" class="pb-2 w-100 d-flex justify-content-between">
          <h3 class="ml-1 mb-2">Lesson Plans</h3>
          <!-- <div>
            <b-button
              class="btn rounded-pill ml-auto"
              variant="primary"
              @click="exportUsers()"
            >
              export users
            </b-button>
            <b-button
              class="btn rounded-pill ml-1"
              variant="primary"
              @click="openInvitation()"
            >
              User Invitation
            </b-button>
          </div> -->
        </b-col>
      </b-row>
      <b-table :items="items" :fields="fields" striped responsive>
        <template #filter> </template>
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{
              row.detailsShowing ? "Hide" : "Show"
            }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1">
                <strong>Full Name : </strong>{{ row.item.full_name }}
              </b-col>
              <b-col md="4" class="mb-1"> </b-col>
              <b-col md="4" class="mb-1">
                <strong>Email : </strong>{{ row.item.email }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>City : </strong>{{ row.item.city }}
              </b-col>
              <b-col md="4" class="mb-1">
                md="4" class="mb-1" >
                <strong>Age : </strong>{{ row.item.age }}
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(avatar)="data">
          <b-avatar :src="data.item.avatar" />
        </template>

        <template #cell(fname)="data">
          <div>
            {{ data.item.fname + " " + data.item.lname }}
          </div>
        </template>

        <template #cell(type)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="modal-1"
      title="User Invitation"
      hide-footer
      v-model="userInvitationModal"
    >
      <b-card-text>
        <SendInvitations />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import SendInvitations from "@/views/modules/admins/SendInvitations.vue";
export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    ToastificationContent,
    SendInvitations,
    BCardText,
    vSelect,
    BLink,
  },
  computed: {
    ...mapGetters({
      items: "lessonPlans/items",
      types: "lessonPlans/types",
    }),
  },
  directives: {
    Ripple,
  },
  watch: {
    checked(newVal) {
      if (newVal) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Form Submitted",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
    },
    "filter.school_id"(newVal) {
      this.$store.dispatch("lessonPlans/lessonPlans", { query: this.filter });
    },
    "filter.type_id"(newVal) {
      this.$store.dispatch("lessonPlans/lessonPlans", { query: this.filter });
    },
  },
  data() {
    return {
      checked: true,
      userInvitationModal: false,
      filter: {
        school_id: null,
        type_id: null,
      },
      fields: [
        { key: "year", label: "Year" },
        { key: "students", label: "No. of students" },
        { key: "class_length", label: "Class length" },
        { key: "times_week", label: "Times a week" },
        { key: "level", label: "Level" },
        { key: "modules", label: "No. of modules" },
        { key: "class_completion", label: "Class completion" },
        "Actions",
      ],
      status: [
        {
          1: "Admin",
          2: "Teacher",
          3: "Head of Department",
          5: "Student",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  methods: {
    init() {
      this.$store.dispatch("lessonPlans/lessonPlans").then((_) => {
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
